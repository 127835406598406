import * as React from "react";
import Panels from "./Panels.component";
import SamplePage from "./SamplePage.component";

const MonochromaticColors = ({ color }) => {
  const [monochromaticColors, setMonochromaticColors] = React.useState([
    {
      id: 1,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 2,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 3,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 4,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 5,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
  ]);

  const setLightness = (colors) => {
    colors[0].lightness = 30;
    colors[1].lightness = 50;
    colors[2].lightness = 60;
    colors[3].lightness = 70;
    colors[4].lightness = 80;
    return colors
  };

  const sortColors = (colors) => {
    let max = colors[0].saturation;
    for (let index = 1; index < 5; index++) {
      if (max > colors[index].saturation) {
        let sortedColors = [];
        sortedColors = colors.slice(index, 5).concat(colors.slice(0, index));
        setLightness(sortedColors);
        return setMonochromaticColors(sortedColors);
      } else max = colors[index].saturation;
    }
    setLightness(colors);
    return setMonochromaticColors(colors);
  };

  const generateMonochromaticColors = () => {
    const colors = [];
    let { hue, saturation, lightness } = color;
    for (let index = 0; index < 5; index++) {
      if (saturation <= 100) {
        colors.push({
          id: index,
          hue: hue,
          saturation: saturation,
          lightness: 12,
        });
        saturation += 20;
      } else {
        saturation -= 100;
        colors.push({
          id: index,
          hue: hue,
          saturation: saturation,
          lightness: 50,
        });
        saturation += 20;
      }
    }
    sortColors(colors);
  };

  React.useEffect(() => {
    generateMonochromaticColors();
  }, [color]);

  return (
    <React.Fragment>
      <Panels title="Monochromatic Colors" colors={monochromaticColors} />
      <SamplePage title="Monochromatic" colors={monochromaticColors} />
    </React.Fragment>
  );
};

export default MonochromaticColors;
