import * as React from 'react';
import * as style from './Button.style.module.scss'

const Button = ({onClick, children}) => {
    return(
        <button onClick={onClick} className={style.button}>{children}</button>
    );

};

export default Button;