import * as React from "react";
import * as style from "./SamplePage.style.module.scss";

const SamplePage = ({ title, colors }) => {
  const primaryColor = `hsl(${colors[3].hue}, ${colors[3].saturation}%,  ${colors[3].lightness}%)`;
  const secoundaryColor = `hsl(${colors[4].hue}, ${colors[4].saturation}%,  ${colors[4].lightness}%)`;

  const spotColor = `hsl(${colors[2].hue}, ${colors[2].saturation}%,  ${colors[2].lightness}%)`;

  const primaryBgColor = `hsl(${colors[0].hue}, ${colors[0].saturation}%,  ${colors[0].lightness}%)`;
  const secoundaryBgColor = `hsl(${colors[1].hue}, ${colors[1].saturation}%,  ${colors[1].lightness}%)`;

  const [showNav, setShowNav] = React.useState("false");
  return (
    <div className={style.sample_page}>
      <div
        className={style.nav}
        style={{
          backgroundColor: primaryColor,
          color: primaryBgColor,
        }}
      >
        <div>Logo</div>
        <ul
          className={style.nav_list}
          style={{
            backgroundColor: primaryColor,
          }}
          show={showNav}
        >
          <li className={style.nav_item}>
            Home
            <div
              className={style.line}
              style={{
                backgroundColor: spotColor,
              }}
            ></div>
          </li>
          <li className={style.nav_item}>
            Gallery
            <div
              className={style.line}
              style={{
                backgroundColor: spotColor,
              }}
            ></div>
          </li>
          <li className={style.nav_item}>
            About
            <div
              className={style.line}
              style={{
                backgroundColor: spotColor,
              }}
            ></div>
          </li>
          <li className={style.nav_item}>
            Contact
            <div
              className={style.line}
              style={{
                backgroundColor: spotColor,
              }}
            ></div>
          </li>
        </ul>
        <div
          onClick={() => {
            showNav === "false" ? setShowNav("true") : setShowNav("false");
          }}
          className={style.nav_mobile}
          show={showNav}
        >
          <span
            style={{
              backgroundColor: primaryBgColor,
            }}
          ></span>
          <span
            style={{
              backgroundColor: primaryBgColor,
            }}
          ></span>
          <span
            style={{
              backgroundColor: primaryBgColor,
            }}
          ></span>
        </div>
      </div>
      <div
        className={style.hero}
        style={{
          backgroundColor: primaryBgColor,
        }}
      >
        <p
          className={style.h1}
          style={{
            color: secoundaryColor,
          }}
        >
          {`${title} `}
          <span
            style={{
              color: spotColor,
            }}
          >
            Theme
          </span>
        </p>
        <p
          className={style.hero_text}
          style={{
            color: secoundaryColor,
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium et
          repudiandae dolorem quae totam aliquid qui minus error ad quia, odio
          animi rerum sequi amet culpa quaerat deserunt aspernatur, dignissimos
          consequuntur incidunt illo possimus veritatis nihil? Exercitationem
          hic maxime commodi minus explicabo corrupti ut nobis minima rem
          aliquam earum numquam, ratione, amet esse mollitia architecto aperiam
          cumque tempora delectus tempore, quas autem obcaecati. Vero laboriosam
          asperiores, cupiditate tenetur eaque temporibus in doloribus
          necessitatibus eius ipsam nulla quam sint, omnis sequi facere qui
          aspernatur iste vitae vel suscipit corporis placeat animi tempora
          aliquam. Officia suscipit autem voluptatibus cumque cupiditate dicta
          placeat?
        </p>
      </div>
      <div
        className={style.section}
        style={{
          backgroundColor: secoundaryBgColor,
        }}
      >
        <p
          className={style.h2}
          style={{
            color: secoundaryColor,
          }}
        >
          {`${title} `}
          <span
            style={{
              color: primaryColor,
            }}
          >
            Theme
          </span>
        </p>
        <p
          className={style.section_text}
          style={{
            color: secoundaryColor,
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium et
          repudiandae dolorem quae totam aliquid qui minus error ad quia, odio
          animi rerum sequi amet culpa quaerat deserunt aspernatur, dignissimos
          consequuntur incidunt illo possimus veritatis nihil? Exercitationem
          hic maxime commodi minus explicabo corrupti ut nobis minima rem
          aliquam earum numquam, ratione, amet esse mollitia architecto aperiam
          cumque tempora delectus tempore, quas autem obcaecati. Vero laboriosam
          asperiores, cupiditate tenetur eaque temporibus in doloribus
          necessitatibus eius ipsam nulla quam sint, omnis sequi facere qui
          aspernatur iste vitae vel suscipit corporis placeat animi tempora
          aliquam. Officia suscipit autem voluptatibus cumque cupiditate dicta
          placeat?
        </p>
        <div className={style.cards_list}>
          <div
            className={style.card}
            style={{
              color: secoundaryColor,
              borderColor: spotColor,
            }}
          >
            <p
              className={style.title}
              style={{
                color: primaryColor,
              }}
            >
              Lorem ipsum dolor sit amet.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Distinctio corporis similique explicabo magnam voluptates, ab nam
              et culpa! Repudiandae non voluptate magnam dignissimos porro
              repellendus, possimus iure nam totam animi!
            </p>
          </div>
          <div
            className={style.card}
            style={{
              color: secoundaryColor,
              borderColor: spotColor,
            }}
          >
            <p
              className={style.title}
              style={{
                color: primaryColor,
              }}
            >
              Lorem ipsum dolor sit amet.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Distinctio corporis similique explicabo magnam voluptates, ab nam
              et culpa! Repudiandae non voluptate magnam dignissimos porro
              repellendus, possimus iure nam totam animi!
            </p>
          </div>
          <div
            className={style.card}
            style={{
              color: secoundaryColor,
              borderColor: spotColor,
            }}
          >
            <p
              className={style.title}
              style={{
                color: primaryColor,
              }}
            >
              Lorem ipsum dolor sit amet.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Distinctio corporis similique explicabo magnam voluptates, ab nam
              et culpa! Repudiandae non voluptate magnam dignissimos porro
              repellendus, possimus iure nam totam animi!
            </p>
          </div>
        </div>
      </div>
      <div
        className={style.footer}
        style={{
          backgroundColor: primaryColor,
          color: primaryBgColor,
        }}
      >
        <div className={style.footer_logo}>
          <p>Logo</p>
        </div>
        <div className={style.footer_col}>
          <p className={style.footer_title}>Menu</p>
          <ul>
            <ul className={style.footer_list}>
              <li className={style.footer_item}>
                Home
                <div
                  className={style.line}
                  style={{
                    backgroundColor: spotColor,
                  }}
                ></div>
              </li>
              <li className={style.footer_item}>
                Gallery
                <div
                  className={style.line}
                  style={{
                    backgroundColor: spotColor,
                  }}
                ></div>
              </li>
              <li className={style.footer_item}>
                About
                <div
                  className={style.line}
                  style={{
                    backgroundColor: spotColor,
                  }}
                ></div>
              </li>
              <li className={style.footer_item}>
                Contact
                <div
                  className={style.line}
                  style={{
                    backgroundColor: spotColor,
                  }}
                ></div>
              </li>
            </ul>
          </ul>
        </div>
        <div className={style.footer_col}>
          <p className={style.footer_title}>Address</p>
        </div>
        <div className={style.footer_col}>
          <p className={style.footer_title}>Contact</p>
        </div>
      </div>
    </div>
  );
};

export default SamplePage;
