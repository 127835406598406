import * as React from "react";
import Panels from "./Panels.component";
import SamplePage from "./SamplePage.component";

const TradicColors = ({ color }) => {
  const [triadicColors, setTriadicColors] = React.useState([
    {
      id: 1,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 2,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 3,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 4,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 5,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
  ]);

  const setLightness = (colors) => {
    colors[0].lightness = 30;
    colors[1].lightness = 50;
    colors[2].lightness = 60;
    colors[3].lightness = 70;
    colors[4].lightness = 80;
    return colors;
  };

  const generateTriadicColors = () => {
    const colors = [];
    let { hue, saturation, lightness } = color;
    for (let index = 0; index < 5; index++) {
      if (hue > 360) hue -= 360;
      colors.push({
        id: index,
        hue: hue,
        saturation: saturation,
        lightness: lightness,
      });
      hue += 120;
      if (index === 2) hue += 60;
    }
    setLightness(colors);
    setTriadicColors(colors);
  };

  React.useEffect(() => {
    generateTriadicColors();
  }, [color]);

  return (
    <React.Fragment>
      <Panels title="Triadic Colors" colors={triadicColors} />
      <SamplePage title="Triadic" colors={triadicColors} />
    </React.Fragment>
  );
};

export default TradicColors;
