// extracted by mini-css-extract-plugin
export var card = "SamplePage-style-module--card--52cab";
export var cards_list = "SamplePage-style-module--cards_list--2a9f9";
export var footer = "SamplePage-style-module--footer--10abb";
export var footer_item = "SamplePage-style-module--footer_item--98c4e";
export var footer_list = "SamplePage-style-module--footer_list--7bce8";
export var footer_logo = "SamplePage-style-module--footer_logo--3473c";
export var footer_title = "SamplePage-style-module--footer_title--5acbb";
export var h1 = "SamplePage-style-module--h1--36ddb";
export var h2 = "SamplePage-style-module--h2--1e6d4";
export var hero = "SamplePage-style-module--hero--2fbbf";
export var hero_text = "SamplePage-style-module--hero_text--30d7b";
export var line = "SamplePage-style-module--line--9f8bb";
export var nav = "SamplePage-style-module--nav--4c991";
export var nav_item = "SamplePage-style-module--nav_item--d1b5b";
export var nav_list = "SamplePage-style-module--nav_list--b0223";
export var nav_mobile = "SamplePage-style-module--nav_mobile--e4f92";
export var sample_page = "SamplePage-style-module--sample_page--e4c53";
export var section = "SamplePage-style-module--section--d520c";
export var section_text = "SamplePage-style-module--section_text--da16c";
export var title = "SamplePage-style-module--title--1e4b1";