import * as React from "react";
import Panels from "./Panels.component";
import SamplePage from "./SamplePage.component";

const ComplementaryColors = ({ color }) => {
  const [complementaryColors, setComplementaryColors] = React.useState([
    {
      id: 1,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 2,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 3,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 4,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
    {
      id: 5,
      hue: 10,
      saturation: 10,
      lightness: 10,
    },
  ]);

  const setLightness = (colors) => {
    colors[0].lightness = 30;
    colors[1].lightness = 50;
    colors[2].lightness = 60;
    colors[3].lightness = 70;
    colors[4].lightness = 80;
    return colors;
  };

  const generateComplementaryColors = () => {
    const colors = [];
    let { hue, saturation, lightness } = color;
    for (let index = 0; index < 5; index++) {
      if (hue > 360) hue -= 360;
      if (index % 2 === 0) {
        colors.push({
          id: index,
          hue: hue,
          saturation: saturation,
          lightness: lightness,
        });
        hue += 180;
      } else {
        colors.push({
          id: index,
          hue: hue,
          saturation: saturation,
          lightness: lightness,
        });
        hue += 30;
      }
    }
    setLightness(colors);
    setComplementaryColors(colors);
  };

  React.useEffect(() => {
    generateComplementaryColors();
  }, [color]);

  return (
    <React.Fragment>
      <Panels title="Complementary Colors" colors={complementaryColors} />
      <SamplePage title="Complementary" colors={complementaryColors} />
    </React.Fragment>
  );
};

export default ComplementaryColors;
