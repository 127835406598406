import * as React from "react";
import * as style from "./ColorPalette.style.module.scss";


const Panels = ({ title, colors }) => {
  return (
    <React.Fragment>
      <h2 className={style.h2}>{title}</h2>
      <ul className={style.palette}>
        {colors.map((color) => (
          <li
            key={color.id}
            className={style.color}
            style={{
              backgroundColor: `hsl(${color.hue},${color.saturation}%,${color.lightness}%)`,
            }}
          >
            <span>{`hsl(${color.hue},${color.saturation}%,${color.lightness}%)`}</span>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default Panels;