import * as React from "react";
import Layout from "../../../components/layout/Layout.component";
import Button from "../../../components/button/Button.component";
import MonochromaticColors from "../../../components/color-palette/MonochromaticColors.component";
import AnalogusColors from "../../../components/color-palette/AnalogusColors.component";
import ComplementaryColors from "../../../components/color-palette/ComplementaryColors.component";
import TradicColors from "../../../components/color-palette/TriadicColors.component";
import CompoundColors from "../../../components/color-palette/CompoundColors.component";
import * as style from "../../../components/color-palette/ColorPalette.style.module.scss";

const ColorPalette = () => {
  const [randomColors, setRandomColors] = React.useState([]);
  const [selectColor, setSelectColor] = React.useState({
    id: 1,
    hue: 1,
    saturation: 1,
    lightness: 1,
  });

  const generateRandomColor = () => {
    const hue = Math.floor(Math.random() * (360 - 0 + 1) + 0);
    const saturation = Math.floor(Math.random() * (100 - 0 + 1) + 0);
    const lightness = Math.floor(Math.random() * (80 - 30 + 1) + 30);
    const color = [hue, saturation, lightness];
    return color;
  };

  const generateRandomColors = () => {
    const colors = [];
    for (let index = 0; index < 5; index++) {
      const [hue, saturation, lightness] = generateRandomColor();
      colors.push({
        id: index,
        hue: hue,
        saturation: saturation,
        lightness: lightness,
      });
    }
    setRandomColors(colors);
    return setSelectColor(colors[0]);
  };

  React.useEffect(() => {
    generateRandomColors();
  }, []);

  return (
    <Layout>
      <div className={style.button}>
        <Button
          onClick={() => {
            generateRandomColors();
          }}
        >
          Generate
        </Button>
      </div>

      <h1 className={style.h1}>Color Palette Generator</h1>
      <p className={style.text}>
        Generate your color palette. You will receive a random set of five
        colors. Then, on the basis of the first one, the remaining palettes will
        be selected. You can change the color by clicking on one of five random
        ones. You don't like the colors you've drawn? No problem! Generate new
        ones by pressing the "Generate" button. Each palette has an example page
        where you can see what the selection of these colors looks like.
      </p>
      <h2 className={style.h2}>Random Colors</h2>
      <ul className={style.palette}>
        {randomColors.map((color) => (
          <li
            onClick={() => {
              setSelectColor(color);
            }}
            key={color.id}
            className={style.color}
            style={{
              backgroundColor: `hsl(${color.hue},${color.saturation}%,${color.lightness}%)`,
              cursor: "pointer",
            }}
          >
            <span>{`hsl(${color.hue},${color.saturation}%,${color.lightness}%)`}</span>
          </li>
        ))}
      </ul>
      <MonochromaticColors color={selectColor} />
      <AnalogusColors color={selectColor} />
      <ComplementaryColors color={selectColor} />
      <TradicColors color={selectColor} />
      <CompoundColors color={selectColor} />
    </Layout>
  );
};
export default ColorPalette;

export function Head() {
  return <title>Color palette</title>;
}
